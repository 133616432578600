export default `<!-- Begin Sendinblue Form -->
<!-- START - We recommend to place the below code in head tag of your
website html -->
<style>
  @font-face {
    font-display: block;
    font-family: Roboto;
    src:
      url(https://assets.sendinblue.com/font/Roboto/Latin/normal/normal/7529907e9eaf8ebb5220c5f9850e3811.woff2) format("woff2"),
      url(https://assets.sendinblue.com/font/Roboto/Latin/normal/normal/25c678feafdc175a70922a116c9be3e7.woff) format("woff")
  }

  @font-face {
    font-display: fallback;
    font-family: Roboto;
    font-weight: 600;
    src:
      url(https://assets.sendinblue.com/font/Roboto/Latin/medium/normal/6e9caeeafb1f3491be3e32744bc30440.woff2) format("woff2"),
      url(https://assets.sendinblue.com/font/Roboto/Latin/medium/normal/71501f0d8d5aa95960f6475d5487d4c2.woff) format("woff")
  }

  @font-face {
    font-display: fallback;
    font-family: Roboto;
    font-weight: 700;
    src:
      url(https://assets.sendinblue.com/font/Roboto/Latin/bold/normal/3ef7cf158f310cf752d5ad08cd0e7e60.woff2) format("woff2"),
      url(https://assets.sendinblue.com/font/Roboto/Latin/bold/normal/ece3a1d82f18b60bcce0211725c476aa.woff) format("woff")
  }

  #sib-container input:-ms-input-placeholder {
    text-align: left;
    font-family: "Helvetica", sans-serif;
    color: #c0ccda;
  }

  #sib-container input::placeholder {
    text-align: left;
    font-family: "Helvetica", sans-serif;
    color: #c0ccda;
  }

  #sib-container textarea::placeholder {
    text-align: left;
    font-family: "Helvetica", sans-serif;
    color: #c0ccda;
  }
</style>
<link rel="stylesheet" href="https://sibforms.com/forms/end-form/build/sib-styles.css">
<!-- END - We recommend to place the above code in head tag of your
  website html -->
<!-- START - We recommend to place the below code where you want the
  form in your website html -->
<div class="sib-form" style="text-align: center;
  background-color: #EFF2F7; ">
  <div id="sib-form-container" class="sib-form-container">
    <div id="error-message" class="sib-form-message-panel" style="font-size:16px; text-align:left;
  font-family: Helvetica, sans-serif; color:#661d1d;
  background-color:#ffeded; border-radius:3px;
  border-color:#ff4949;max-width:540px;">
      <div class="sib-form-message-panel__text
  sib-form-message-panel__text--center">
        <svg viewBox="0 0 512 512" class="sib-icon
  sib-notification__icon">
          <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61
  216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216
  216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248
  248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49
  120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556
  11.5-11.99 11.5h-8.979c-6.433
  0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256
  340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536
  28-28-12.536-28-28-28z" />
        </svg>
        <span class="sib-form-message-panel__inner-text">
          Nous n&#039;avons pas pu valider votre
          abonnement.
        </span>
      </div>
    </div>
    <div></div>
    <div id="success-message" class="sib-form-message-panel" style="font-size:16px; text-align:left;
  font-family:Helvetica, sans-serif; color:#085229;
  background-color:#e7faf0; border-radius:3px;
  border-color:#13ce66;max-width:540px;">
      <div class="sib-form-message-panel__text
  sib-form-message-panel__text--center">
        <svg viewBox="0 0 512 512" class="sib-icon
  sib-notification__icon">
          <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248
  248-111.033 248-248S392.967 8 256 8zm0 464c-118.664
  0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055
  216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15
  376.071c-4.705 4.667-12.303
  4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.5
  2-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533
  163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668
  4.705 4.637 12.303-.068 16.97z" />
        </svg>
        <span class="sib-form-message-panel__inner-text">
          Votre inscription a été faite.
        </span>
      </div>
    </div>
    <div></div>
    <div id="sib-container" class="sib-container--large
  sib-container--vertical" style="text-align:center;
  background-color:rgba(255,255,255,1); max-width:540px;
  border-radius:3px; border-width:1px; border-color:#C0CCD9;
  border-style:solid;">
      <form id="sib-form" method="POST" action="https://2fc70f3b.sibforms.com/serve/MUIEAN7pfHNVB9G6hvG74YR-ZyDHXDOp0IonZj8du4TrS791_9lUYbGEbczySCjjFPtOch60XWWs51JDgW81_hnX12_LZWpcoVO3Khwqpe-2iyjo1prtGiB1jDqKlJ5xCXgDFVqVqtYO8TBQ7rdv86gisMKKpygp2ffckuvMQQlE7pPa_PhxClE6mE2SY13Y5yIUPIkshRHZY0Nr" data-type="subscription">
        <div style="padding: 16px 0;">
          <div class="sib-form-block sib-image-form-block" style="text-align: center">
            <img src="https://img.mailinblue.com/2526846/images/rnb/original/5e4e99b5338b30504125374f.png" style="width: 500px;height: 281px;" alt="" title="" />
          </div>
        </div>
        <div style="padding: 16px 0;">
          <div class="sib-form-block" style="font-size:32px;
  text-align:center; font-weight:700; font-family:Helvetica,
  sans-serif; color:#3C4858; background-color:transparent;">
            <p>&nbsp;<strong>Toutes les informations ici</strong></p>
          </div>
        </div>
        <div style="padding: 16px 0;">
          <div class="sib-form-block" style="font-size:16px;
  text-align:left; font-family:Helvetica, sans-serif;
  color:#3C4858; background-color:transparent;">
            <div class="sib-text-form-block">
              <p>Nous allons vous donner toutes les informations sur le
                guide 800 exercices dont vous avez besoin (prix, mode paiement,
                ...).</p>
              <p>
                <br>
              </p>
              <p>Vous allez recevoir ses informations par email.</p>
              <p>
                <br>
              </p>
              <p>Si vous n'avez pas reçu cet email dans les 2 minutes
                après votre inscription, regardez dans vos messages indésirables.
                &nbsp;</p>
              <p>
                <br>
              </p>
              <p>Si non, contactez-nous nous vous renverrons cet
                email.</p>
              <p>
                <br>
              </p>
              <p>Merci</p>
            </div>
          </div>
        </div>
        <div style="padding: 16px 0;">
          <div class="sib-input sib-form-block">
            <div class="form__entry entry_block">
              <div class="form__label-row ">
                <label class="entry__label" style="font-size:16px;
  text-align:left; font-weight:700; font-family:Helvetica,
  sans-serif; color:#3c4858;" for="PRENOM" data-required="*">
                  Entrez votre prénom
                </label>
                <div class="entry__field">
                  <input class="input" maxlength="200" type="text" id="PRENOM" name="PRENOM" autocomplete="off"
                    placeholder="Votre prénom" data-required="true" required />
                </div>
              </div>
              <label class="entry__error entry__error--primary" style="font-size:16px; text-align:left;
  font-family:Helvetica, sans-serif; color:#661d1d;
  background-color:#ffeded; border-radius:3px; border-color:#ff4949;">
              </label>
            </div>
          </div>
        </div>
        <div style="padding: 16px 0;">
          <div class="sib-input sib-form-block">
            <div class="form__entry entry_block">
              <div class="form__label-row ">
                <label class="entry__label" style="font-size:16px;
  text-align:left; font-weight:700; font-family:Helvetica,
  sans-serif; color:#3c4858;" for="EMAIL" data-required="*">
                  Entrez votre email
                </label>
                <div class="entry__field">
                  <input class="input" type="text" id="EMAIL" name="EMAIL" autocomplete="off"
                    placeholder="Votre meilleur email" data-required="true" required />
                </div>
              </div>
              <label class="entry__error entry__error--primary" style="font-size:16px; text-align:left;
  font-family:Helvetica, sans-serif; color:#661d1d;
  background-color:#ffeded; border-radius:3px; border-color:#ff4949;">
              </label>
              <label class="entry__specification" style="font-size:12px; text-align:left;
  font-family:Helvetica, sans-serif; color:#8390A4;">
                Aucun spam, promis. Désinscription possible en 1 clic.
              </label>
            </div>
          </div>
        </div>
        <div style="padding: 16px 0;">
          <div class="sib-form-block" style="text-align: center">
            <button class="sib-form-block__button
  sib-form-block__button-with-loader" style="font-size:16px;
  text-align:center; font-weight:700; font-family:Helvetica,
  sans-serif; color:#FFFFFF; background-color:#3d85f2; border-radius:3px;
  border-width:0px;" form="sib-form" type="submit">
              <svg class="icon clickable__icon progress-indicator__icon
  sib-hide-loader-icon" viewBox="0 0 512 512">
                <path d="M460.116
  373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874
  30.137-59.886
  28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676
  C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337
  12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696
  44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
              </svg>
              Recevoir les informations
            </button>
          </div>
        </div>
        <div style="padding: 16px 0;">
          <div class="sib-form-block" style="font-size:10px;
  text-align:left; font-family:Helvetica, sans-serif;
  color:#73767b; background-color:transparent;">
            <div class="sib-text-form-block">
              <p><strong>Nous respectons votre vie privée et vos
                  données ne seront jamais partagées avec des tiers. Votre adresse e-mail
                  est uniquement utilisée pour vous envoyer des informations sur la
                  préparation physique en football, ainsi que pour vous partager des
                  offres sur les programmes et formation. Vous pouvez vous désabonner à
                  tout moment grâce au lien inclus en bas de chaque
                  e-mail.</strong>&nbsp;</p>
            </div>
          </div>
        </div>
        <input type="text" name="email_address_check" value="" class="input--hidden">
        <input type="hidden" name="locale" value="es">
      </form>
    </div>
  </div>
</div>
<!-- END - We recommend to place the below code where you want the form
  in your website html -->
<!-- START - We recommend to place the below code in footer or bottom
  of your website html -->
<script>
  window.REQUIRED_CODE_ERROR_MESSAGE = 'Elija un código de país';
  window.LOCALE = 'es';
  window.EMAIL_INVALID_MESSAGE = window.SMS_INVALID_MESSAGE = "Les informations que vous avez fournies sont invalides. Vérifiez le format du champ et réessayez.";
  window.REQUIRED_ERROR_MESSAGE = "Ce champ ne peut pas être vide. ";
  window.GENERIC_INVALID_MESSAGE =
    "Les informations que vous avez fournies sont invalides. Vérifiez le format du champ et réessayez.";
  window.translation = {
    common: {
      selectedList: '{quantity} lista seleccionada',
      selectedLists: '{quantity} listas seleccionadas'
    }
  };
  var AUTOHIDE = Boolean(0);
</script>
<script src="https://sibforms.com/forms/end-form/build/main.js"></script>
<!-- END - We recommend to place the above code in footer or bottom of
  your website html -->
<!-- End Sendinblue Form -->`