import SubscriptionModal from "@/components/SubscriptionModal";
export default {
  components: {
    SubscriptionModal,
  },
  methods: {
    openModal(link) {
      if((this.lang === 'es' || this.lang === 'en') && link != null) {
        console.log(link)
        window.open(link, "_blank")
      } else {
        window.$("#subscription-modal").modal("show");
      }
    },
  }
};