<template>
  <div id="subscription-modal" class="modal modal-centered fade">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-2 py-md-4">
          <div v-html="$data[content]"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SubscriptionModalContent1 from "@/components/templates/subscription/1";
  import SubscriptionModalContent2 from "@/components/templates/subscription/2";
  import SubscriptionModalContent3 from "@/components/templates/subscription/3";
  import SubscriptionModalContent4 from "@/components/templates/subscription/4";
  import SubscriptionModalContent5 from "@/components/templates/subscription/5";
  import SubscriptionModalContent6 from "@/components/templates/subscription/6";
  import SubscriptionModalContent7 from "@/components/templates/subscription/7";
  export default {
    props: {
      contentId: String,
    },
    data() {
      return { SubscriptionModalContent1, SubscriptionModalContent2, SubscriptionModalContent3, SubscriptionModalContent4, SubscriptionModalContent5, SubscriptionModalContent6, SubscriptionModalContent7 };
    },
    computed: {
      content() {
        const id = this.contentId ? this.contentId : "1";
        return "SubscriptionModalContent" + id;
      }
    }
  }
</script>